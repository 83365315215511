import { css } from '@emotion/core';

export default css({
    overflow: 'auto',
    height: '100vh',
    background: 'rgb(249, 249, 249)',
    '& .back-to': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '90px auto 35px',
        padding: '0px 10px',
        maxWidth: 720,
        '& > a': {
            color: '#666',
            textDecoration: 'none'
        }
    },
    '& .post-template': {
        maxWidth: 720,
        backgroundColor: '#FFF',
        margin: '0px auto',
        padding: 25,
        boxSizing: 'border-box',
        '&-body': {
            '& h3': {
                width: '100%',
                fontSize: 30,
                textAlign: 'center',
                color: '#1f300d',
                marginBottom: 40,
            },
            '& p': {
                lineHeight: '20px',
                marginBottom: 15,
                textAlign: 'justify'
            },
            '& strong': {
                fontWeight: 'bold'
            },
            '& em': {
                fontStyle: 'italic',
                display: 'inline-block'
            }
        },
    }
});
