import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Header from '../../../components/common/Header';

import DownloadIcon from '../../../helpers/icons/download';
import styles from './styles';

const ComunicadoTemplate = (props) => {
    const { data: { strapiComunicados: data } } = props;
    const onClickLink = (e) => {
        e.preventDefault();
        window.history.back();
    };
    return (
        <div css={styles}>
            <Header section="news" />
            <div className="back-to">
                <a // eslint-disable-line
                    href="#"
                    onClick={onClickLink}
                    rel="nofollow noopener noreferrer"
                >
                    &larr; Atrás
                </a>
                <a
                    target="_blank"
                    href={`${process.env.STRAPI_API_URL}${data.file.url}`}
                    rel="nofollow noopener noreferrer"
                >
                    <DownloadIcon />
                </a>
            </div>
            <article className="post-template">
                <div
                    className="post-template-body"
                >
                    <ReactMarkdown>
                        { data.description }
                    </ReactMarkdown>
                </div>
            </article>
        </div>
    );
};

ComunicadoTemplate.propTypes = {
    data: PropTypes.object.isRequired
};

export const pageQuery = graphql`
    query ComunicadoByPath($slug: String) {
        strapiComunicados(slug: { eq: $slug }) {
            slug
            file {
                url
            }
            description
        }
    }
`;

export default ComunicadoTemplate;
